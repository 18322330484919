import React from 'react'
import styled from 'styled-components'
import { color, ma, font, mq } from 'src/lib/styles/variable'
import { Link } from 'gatsby'

type LinkProps = {
  href?: string
  target?: string
  rel?: string
}

type ButtonProps = {
  onClick?: () => void
}

type Props = LinkProps &
  ButtonProps & {
    disabled?: boolean
    type?: ('for_recruit' | 'for_thanks' | 'color_yellow' | 'normal')[]
    element?: 'Link' | 'Button' | 'A'
  }

const Button: React.FCX<Props> = (props) => {
  if (props.element === 'A') {
    return (
      <a
        target="_blank"
        rel="noreferrer"
        className={`${props.className} ${props.type?.join(' ')}`}
        href={props.href}
      >
        {props.children}
      </a>
    )
  }

  if (props.element === 'Link') {
    return (
      <Link
        to={props.href}
        className={`${props.className} ${props.type?.join(' ')}`}
      >
        {props.children}
      </Link>
    )
  }

  return (
    <button
      type="button"
      className={`${props.className}  ${props.type?.join(' ')} ${
        props.disabled && 'disabled'
      }`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

export const MainButton = styled(Button)`
  background-color: ${color.main};
  border-radius: 45px;
  text-align: center;
  box-shadow: 0px 0px 24px 2px rgba(0, 0, 0, 0.1);
  color: #ffffff;

  &--for_recruit {
    bottom: 60px;
  }

  &.normal {
    ${font(16)}
    font-weight: bold;
    background-color: #6fcce3;
    border-radius: 45px;
    height: 60px;
    width: 280px;
    line-height: 60px;
    display: block;

    ${mq('tab')} {
      ${font(14)}
      height: 50px;
      line-height: 50px;
      width: 220px;
    }
  }

  &.for_thanks {
    ${ma};
    ${font(16)}
    font-weight: 600;
    width: 280px;
    line-height: 60px;
    height: 60px;
    margin-top: 40px;

    ${mq('tab')} {
      ${font(14)};
      width: 220px;
      height: 50px;
      line-height: 50px;
    }
  }

  &.color_yellow {
    background-color: #ffe953;
    color: #000000;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    background-color: ${color.gray};
  }
`

export default Button
