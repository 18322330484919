/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import Wrapper from 'src/components/shared/Wrapper'
import Title from 'src/components/shared/Title'
import FlowTable from 'src/components/shared/Table/Flow'
import { CenteredTitle } from 'src/components/shared/Title/CenteredTitle'

const Flow: React.FC = () => {
  return (
    <Wrapper>
      <Title
        type={['color_main', 'jp_main']}
        text="スムーズ導入の流れ"
        sub="HOW TO START"
      />
      <CenteredTitle>
        最短1営業日で導入可能！ <br />
        企業様からは導入・維持費一切いただきません
      </CenteredTitle>
      <FlowTable
        data={[
          { text: `ホームページから\nお問い合わせください。` },
          { text: `弊社担当者が\nサービスのご説明に伺います。` },
          { text: 'スムーズの導入に関する申込書をご記入いただきます。' },
          { text: '従業員様向けのスムーズ使い方マニュアルをお渡しします。' },
          { text: '随時住まいをサポートさせていただきます。' }
        ]}
      />
    </Wrapper>
  )
}

export default Flow
